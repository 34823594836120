import React from "react";
import Page from "../Page/Page";

class Unreal extends Page {
  renderPageContent(): JSX.Element {
    return (
      <div>
        Coming soon...
      </div>
    );
  }
}

export default Unreal;